import React from 'react';

import TrainerClass from '../Class/Class';

import './Day.css';

const Day = ({ day, classes }) => {
  return (
    <article className="day">
      <div className="day__header">{day}</div>
      <div className="day__classes">
        {classes.map((trainerClass, index) => (
          <TrainerClass key={index} {...trainerClass} />
        ))}
      </div>
    </article>
  );
};

export default Day;
