import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createLivlyTheme } from '@livly/styleguide-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import TrainerSchedule from './pages/TrainerSchedule';
import Services from './pages/Services';
import PasswordReset from './pages/PasswordReset';
import PrivateRoute from './components/PrivateRoute';

const App = () => (
  <MuiThemeProvider theme={createLivlyTheme()}>
    <Router>
      <Switch>
        <Route path="/" exact render={() => <div>home</div>} />
        <Route path="/password-reset/:name" component={PasswordReset} />
        <PrivateRoute
          path="/service/list"
          render={(props: any) => <Services {...props} />}
        />
        <PrivateRoute
          path="/trainerschedule"
          render={(props: any) => <TrainerSchedule {...props} />}
        />
      </Switch>
    </Router>
  </MuiThemeProvider>
);

export default App;
