import React from 'react';

import Day from './Day/Day';
import Trainers from './Trainers/Trainers';
import Notice from './Notice';

import {
  buildingMapper,
  getBuilding,
  mapTrainersToClass,
  mapTrainersToBuilding
} from './utils';
import './style.css';

class TrainerSchedule extends React.Component {
  state = { building: null };

  componentDidMount() {
    const id = buildingMapper(parseInt(this.props.buildingId, 10));
    const building = getBuilding(id);
    let trainers = [];

    if (building) {
      mapTrainersToClass(building.schedule);
      trainers = mapTrainersToBuilding(id);
    }

    this.setState({ building, trainers });
  }

  render() {
    const { building, trainers } = this.state;

    if (!building)
      return <div className="schedule__coming-soon">Coming soon!</div>;

    return (
      <div className="trainer-schedule-wrapper">
        <div className="trainer__header">
          in-house fitness classes brought to you by Livly
        </div>
        <section className="trainer-schedule">
          {building.schedule.map((day, index) => (
            <Day key={index} {...day} />
          ))}
        </section>
        <Notice />
        <Trainers trainers={trainers} />
      </div>
    );
  }
}

export default TrainerSchedule;
