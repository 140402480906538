import { classSchedule, trainers } from './data';

// eslint-disable-next-line
const buildings = {
  1: 'iTexico',
  2: 'Bush Temple',
  3: 'Lawrence House',
  4: 'Bachelor',
  5: 'Livly Lofts',
  6: 'Microsoft',
  7: 'Clark 800 LLC'
};

const buildingsByEnvironment = {
  development: {
    720: 1,
    359: 2,
    1: 3,
    749: 4
  },
  'quality-assurance': {
    1: 1,
    30: 2,
    41: 3,
    371: 4,
    413: 5
  },
  production: {
    1: 1,
    42: 2,
    111: 3,
    73: 4,
    526: 5,
    432: 6,
    553: 7
  }
};

export const buildingMapper = id => {
  const localBuildingId =
    buildingsByEnvironment[process.env.REACT_APP_LIVLY_ENV][id];
  return localBuildingId;
};

export const getBuilding = id => {
  return classSchedule[id];
};

export const mapTrainersToClass = schedule => {
  return schedule.map(s => {
    s.classes.map(c => {
      const trainer = trainers[c.trainerId];
      c.name = trainer ? trainer.name : 'TBD';
      return c;
    });
    return s;
  });
};

export const mapTrainersToBuilding = id => {
  const building = classSchedule[id];
  if (building) {
    return building.trainers.map(t => {
      const trainer = trainers[t];
      return trainer;
    });
  }
  return [];
};

export const getScheduleByBuilding = id => {
  const building = classSchedule[id];
  if (building) {
    return building.schedule;
  } else {
    return [];
  }
};
