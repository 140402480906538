export const classSchedule = {
  2: {
    trainers: [1, 2, 3, 4, 5],
    schedule: [
      {
        day: 'Mon',
        classes: [
          {
            trainerId: 5,
            start: '7:30 AM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 1,
            start: '12:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 1,
            start: '6:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          }
        ]
      },
      {
        day: 'Tues',
        classes: [
          {
            trainerId: 3,
            start: '7:00 AM',
            description: 'Boxing'
          },
          {
            trainerId: 1,
            start: '12:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 1,
            start: '6:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 3,
            start: '7:00 PM',
            description: 'Boxing'
          }
        ]
      },
      {
        day: 'Wed',
        classes: [
          {
            trainerId: 5,
            start: '7:30 AM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 1,
            start: '12:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 3,
            start: '12:15 PM',
            description: 'Boxing'
          },
          {
            trainerId: 1,
            start: '6:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 3,
            start: '6:30 PM',
            description: 'Boxing'
          }
        ]
      },
      {
        day: 'Thurs',
        classes: [
          {
            trainerId: 3,
            start: '7:00 AM',
            description: 'Boxing'
          },
          {
            trainerId: 1,
            start: '12:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 1,
            start: '6:00 PM',
            description: 'Legit, bootcamp style'
          },
          {
            trainerId: 4,
            start: '7:00 PM',
            description: 'Full body conditioning'
          }
        ]
      },
      {
        day: 'Fri',
        classes: [
          {
            trainerId: 5,
            start: '7:30 AM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 1,
            start: '12:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 3,
            start: '12:15 PM',
            description: 'Boxing'
          },
          {
            trainerId: 3,
            start: '6:00 PM',
            description: 'Full body conditioning'
          }
        ]
      },
      {
        day: 'Sat',
        classes: [
          {
            trainerId: 3,
            start: '9:00 AM',
            description: 'Full body conditioning'
          },
          {
            trainerId: 1,
            start: '11:00 AM',
            description: 'Legit, bootcamp style',
            isFree: true
          },
          {
            trainerId: 3,
            start: '11:00 AM',
            description: 'Full body conditioning'
          },
          {
            trainerId: 4,
            start: '1:00 PM',
            description: 'High intensity interval training'
          }
        ]
      },
      {
        day: 'Sun',
        classes: [
          {
            trainerId: 4,
            start: '10:00 AM',
            description: 'High intensity interval training'
          }
        ]
      }
    ]
  },
  3: {
    trainers: [4],
    schedule: [
      {
        day: 'Tues',
        classes: [
          {
            trainerId: 4,
            start: '12:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          }
        ]
      },
      {
        day: 'Thurs',
        classes: [
          {
            trainerId: 4,
            start: '12:00 PM',
            description: 'Legit, bootcamp style',
            isFree: true
          }
        ]
      }
    ]
  }
};

export const trainers = {
  1: {
    name: 'Joe',
    tags: ['Boxing Trainer', 'Pro MMA fighter', 'Krav Maga expert'],
    description: `From Westmont, IL, our lead trainer Joe has been shaping performance athletes for the last ten years. Post-college football career, Joe became the original Legit trainer, and pioneered his branded no-rest high intensity interval class. He hates sleeves and loves positive change. Find Joe to channel the fighter in you.`,
    image: '/assets/Joe.png'
  },
  2: {
    name: 'Marko',
    tags: ['DJ Club Master', 'Fútbol Pro'],
    description:
      'From Frankfurt, Germany, Marko is an international man of mystery. Ex pro soccer player, amateur boxer, and ab crusher, Marko will make you say mercy. He runs personal training, small group classes, and full blown Legit classes on the reg.',
    image: '/assets/Marko.png'
  },
  3: {
    name: 'Marlon',
    tags: ['Boxer', 'Guru', 'Guy next door'],
    description: `From Chicago IL, Marlon embodies transformation and positive energy. He's a 2013/2014 gold glove competitor, weight lost specialist, and overall change agent. Marlon is all about conscious living and WILL get you to your individual fitness goal.`,
    image: '/assets/Marlon.png'
  },
  4: {
    name: 'Quan',
    tags: ['Body Builder', 'Marathoner', 'Flexy man'],
    description: `From San Diego, CA, Quan is a former football receiver, safety and overall Adonis. Quan has been crushing classes and personal training sessions all over the country. He will train you to get jacked and fast as hell.`,
    image: '/assets/Quan.jpeg'
  },
  5: {
    name: 'Alfa',
    tags: ['Body Builder', 'Marathoner', 'Flexy man'],
    description: `From San Diego, CA, Quan is a former football receiver, safety and overall Adonis. Quan has been crushing classes and personal training sessions all over the country. He will train you to get jacked and fast as hell.`,
    image: '/assets/Quan.jpeg'
  }
};
