import React from 'react';

import './Trainers.css';

const Trainer = ({ name = 'Trainer', description, tags, image }) => (
  <article className="trainer-card">
    <picture className="trainer-card__top">
      <source type="image/webp" srcSet={`/assets/${name}.webp`} />
      <img src={image} alt={name} className="trainer-card__avatar" />
    </picture>
    <div className="trainer-card__bottom">
      <div className="trainer-card__name">{name}</div>
      <div className="trainer__description">{description}</div>
    </div>
  </article>
);

const Trainers = ({ trainers }) => (
  <section>
    {Object.keys(trainers).map((t, index) => {
      const trainer = trainers[t];
      return <Trainer key={index} {...trainer} />;
    })}
  </section>
);

export default Trainers;
