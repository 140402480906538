import React from 'react';

import './Class.css';

const TrainerClass = ({ trainerId, name, start, description, isFree }) => {
  return (
    <article className="class">
      <div className="class__row">
        <div className="class__field">{start}</div>
        <div className="class__divider">|</div>
        <div className="class__field">{name}</div>
      </div>
      <div className="class__description">
        {description}
        {isFree && <span className="flats__logo" />}
      </div>
    </article>
  );
};

export default TrainerClass;
