import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import { colors } from '@livly/styleguide-components';
import moment from 'moment';

import Appointment from './Appointment';
import { AcuityAppointment } from '../../pages/Services';

const Appointments = (props: Props) => {
  const classes = useStyles();
  const { appointments, loading } = props;

  const uncancelledAppointments: AcuityAppointment[] = appointments.filter(
    a => !a.canceled
  );
  const upcomingAppointments: AcuityAppointment[] = uncancelledAppointments.filter(
    a => moment().isSameOrBefore(a.datetime)
  );
  const pastAppointments: AcuityAppointment[] = uncancelledAppointments.filter(
    a => moment().isAfter(a.datetime)
  );

  if (loading) {
    return (
      <>
        {Array.from(new Array(3)).map((appt, index) => (
          <Appointment key={index} appointment={appt} />
        ))}
      </>
    );
  }

  if (!appointments.length) {
    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h2" gutterBottom>
          No bookings
        </Typography>
        <Typography variant="body2" className={classes.noBookingsSubheader}>
          Your scheduled bookings will appear here
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <Box py={3}>
        <Typography className={classes.bookingsTitle}>
          upcoming ({upcomingAppointments.length})
        </Typography>
        {!upcomingAppointments.length ? (
          <Box pt={4}>
            <Typography>No upcoming bookings</Typography>
          </Box>
        ) : (
          upcomingAppointments.map(appt => <Appointment appointment={appt} />)
        )}
      </Box>
      <Box py={3}>
        <Typography className={classes.bookingsTitle}>
          past ({pastAppointments.length})
        </Typography>
        {!!pastAppointments.length &&
          pastAppointments.map(appt => (
            <Appointment key={appt.id} appointment={appt} />
          ))}
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  noBookingsSubheader: {
    fontSize: theme.typography.pxToRem(16)
  },
  bookingsTitle: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(13.5),
    fontWeight: 500,
    color: colors.bugs()
  }
}));

interface OwnProps {
  loading: boolean;
  appointments: AcuityAppointment[];
}

type Props = OwnProps;

export default Appointments;
