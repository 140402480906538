import React from 'react';
import {
  Box,
  Typography,
  Theme,
  Avatar,
  Divider,
  makeStyles,
  ButtonBase
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Icon } from '@livly/styleguide-components';

import { AcuityAppointment } from '../../pages/Services';

const Appointment = (props: Props) => {
  const { appointment } = props;
  const classes = styles();

  if (!appointment) {
    return (
      <Box py={2} display="flex" width="100%">
        <Skeleton className={classes.avatar} variant="circle"></Skeleton>
        <Box flexGrow={1}>
          <Skeleton width="50%" className={classes.skeletonText} />
          <Skeleton width="40%" className={classes.skeletonText} />
          <Skeleton width="45%" className={classes.skeletonText} />
        </Box>
      </Box>
    );
  }

  return (
    <React.Fragment key={appointment.id}>
      <ButtonBase
        disableRipple
        disableTouchRipple
        className={classes.button}
        onClick={() => {
          window.location.href = appointment.confirmationPage;
        }}
      >
        <Box py={2} display="flex">
          <Avatar className={classes.avatar}>AG</Avatar>
          <Box>
            <Typography gutterBottom className={classes.title}>
              {appointment.type}
            </Typography>
            <Typography gutterBottom>
              <Icon name="calendar" /> {appointment.date}
            </Typography>
            <Typography>
              <Icon name="clock" weight="light" /> {appointment.time}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
      <Divider />
    </React.Fragment>
  );
};

const styles = makeStyles((theme: Theme) => ({
  button: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    width: '100%'
  },
  skeletonText: {
    margin: theme.spacing(0, 0, 0.35, 0)
  },
  avatar: {
    height: 68,
    width: 68,
    marginRight: theme.spacing(2)
  },
  title: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(17)
  }
}));

interface OwnProps {
  appointment: AcuityAppointment;
}

type Props = OwnProps;

export default Appointment;
