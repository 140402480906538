import React from 'react';
import {
  isMobile,
  isAndroid,
  browserVersion,
  isChrome
} from 'react-device-detect';
import styled from 'styled-components';
import qs from 'qs';

const ApplicationMapper = {
  binway: 'Livly L',
  onboarding: 'Welcome portal'
};

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    let {
      location: { search } = {},
      match: { params: { name = '' } = {} } = {}
    } = props;

    const parsed = qs.parse(search.replace(/\?/gi, ''));
    const email = parsed['email'] || '';

    const applicationName = name ? ApplicationMapper[name.toLowerCase()] : '';

    this.state = { applicationName, email };

    if (
      isMobile &&
      !this.isAndroidChromeVersionPost25User() &&
      applicationName === ApplicationMapper.binway
    ) {
      window.location.href = `livly://login?email=${email}`;
    }
  }

  isAndroidChromeVersionPost25User = () =>
    isMobile && isAndroid && isChrome && parseInt(browserVersion) >= 25;

  render() {
    const { applicationName } = this.state;

    return (
      <Container>
        <Title>
          livly {this.state.applicationName} password reset successful
        </Title>
        <Logo src="/assets/livly_logo.svg" alt="livly" />

        {applicationName === ApplicationMapper.onboarding && (
          <Subtitle>
            you've successfully reset your password for the{' '}
            {this.state.applicationName} app. now,{' '}
            <a
              href={process.env.REACT_APP_ONBOARDING_LOGIN_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              go here
            </a>{' '}
            and enter your new password to login.
          </Subtitle>
        )}

        {applicationName === ApplicationMapper.binway && (
          <Subtitle>
            you've successfully reset your password for the{' '}
            {this.state.applicationName} app. now,{' '}
            {this.isAndroidChromeVersionPost25User() ? (
              <Link
                href={`intent://login/#Intent;scheme=livly;package=com.livly.android.livly_resident;S.email=${
                  this.state.email
                };end`}
              >
                open up your app
              </Link>
            ) : (
              'open up your app'
            )}{' '}
            and enter your new password to login.
          </Subtitle>
        )}
      </Container>
    );
  }
}

export default PasswordReset;

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  height: 100vh;
  background-color: #faede4;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
`;

const Title = styled.h2`
  font-family: GeoMetria-Bold;
  text-align: center;
`;

const Subtitle = styled.p`
  text-align: center;
`;

const Logo = styled.img`
  margin: 80px 0;
`;

const Link = styled.a``;
