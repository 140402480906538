import React from 'react';
import queryString from 'qs';

import TrainerSchedule from '../components/TrainerSchedule';

class ScreensTrainerSchedule extends React.Component {
  render() {
    const parsed = queryString.parse(this.props.location.search);

    const building = parsed['?building'];

    return (
      <div>
        <TrainerSchedule buildingId={building} />
        <style>
          {`
            html,
            body {
              background-color: #F6F6F6;
            }
          `}
        </style>
      </div>
    );
  }
}

export default ScreensTrainerSchedule;
