import React from 'react';

const Notice = () => (
  <section className="schedule__extra">
    <p className="schedule__free-notice">
      Look for the <span className="flats__logo" /> for free classes
    </p>
    <p>
      For all other class inquiries, reach out to{' '}
      <a href="mailto:lifestyle@livly.io">lifestyle@livly.io</a>
    </p>
  </section>
);

export default Notice;
