import React from 'react';
import qs from 'qs';

import { Route } from 'react-router-dom';

const PrivateRoute = ({ render, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let { location: { state: { token } = {}, search } = {} } = props;

      if (!token) {
        const parsed = qs.parse(search.replace(/\?/gi, ''));
        token = parsed['token'];
      }

      return render({ token, ...props });
    }}
  />
);

export default PrivateRoute;
